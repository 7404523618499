.login-container{
    width: 100%;
    height: 100vh;
    /* background-color: red; */
}

.loginHeader{
    /* Frame 137 */

/* position: absolute; */
width: 100%;
height: 150px;

display: flex;
flex-direction: row;
align-content: center;
justify-content: center;
background: #252A34;

}

.loginHeader > img{
    margin: auto 0;
    height: 100px;
}

.completetabsContainer{
    width: 100%;

    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
align-items: center;
justify-content: flex-start;
    /* background-color: red; */
    height: calc(100vh - 150px);
    position: relative;
}

.tab-container{
    width: 80%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 30px 0px;
    /* position: absolute;
    
    top: 0px; */
}

.tab-container > button{
/* Frame 139 */

/* Auto layout */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 5px 0px;
gap: 10px;



font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 31px;
line-height: 42px;
/* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
leading-trim: both;
text-edge: cap;

color: #252A34;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


}

.tab-container > .active{
    /* Frame 138 */

/* Auto layout */
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 5px 0px;
gap: 10px;


font-family: 'Open Sans';
font-style: italic;
font-weight: 600;
font-size: 31px;
line-height: 42px;
/* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
leading-trim: both;
text-edge: cap;

color: #2E8B57;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
border-bottom: 2px solid rgba(46, 139, 87, 1)


}

.loginSingUP{
    width: 100%;
    max-width: 400px;
    overflow-y: scroll;
}


.loginSingUP input{

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
width: 100%;
line-height: 38px;
/* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
leading-trim: both;
text-edge: cap;
letter-spacing: -0.01em;

color: #A9A9A9;
margin: 20px 0 ;


border-bottom: 2px solid #252A34;

}

.loginSingUP button{
    /* Frame 140 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: 50px;
gap: 10px;

background: #2E8B57;
border-radius: 4px;
margin: 30px 0 0 0;

font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 38px;
/* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
leading-trim: both;
text-edge: cap;
letter-spacing: -0.01em;

color: #FFFFFF;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


}


.loginSingUP .input-group select{
    /* /* width: 300px; */
    border: 0;
    background-color: transparent;
    /* margin: 0 0 0 10px; */
    border-bottom: 2px solid #252A34; 
}

.loginSingUP .input-group select:focus{
    outline: none;
    box-shadow: none;
    /* border-color: transparent; */
}

.inp2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inp2 > input , select{
    width: 47%;
}

.inp2 select{
    width: 47%;
    border: 0;
    /* height: 100%; */
    /* padding: 10px 0; */
    background-color: transparent;
    /* margin: 0 0 0 10px; */
    border-bottom: 2px solid #252A34;
    height: 42px;
    margin: 20px 0;
}