.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Background overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  /* Add Items */

  box-sizing: border-box;
  /* width: 300px; */
  /* height: 300px; */

  background: #ffffff;
  border: 1px solid #a9a9a9;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 30px;
}

.modalContentInner {
  width: 400px;
  /* height: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalContentInner > h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 40px;
  /* identical to box height */
  text-align: center;

  color: #000000;
}

.modalContentInner .inputContainer {
  width: 300px;
  /* background-color: black; */
}

.modalContentInner .inputContainer input {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  /* Frame 106 */
  border: 1px solid rgba(169, 169, 169, 1);

font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 18px;
line-height: 25px;
letter-spacing: -0.03em;

color: #000000;
font-stretch: 95;
padding: 10px 10px 10px 15px;

  
  margin: 10px 0;
}


.modalContentInner .inputContainer input::placeholder{
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  letter-spacing: -0.03em;
  
  color: #8d8d8d;
}

.modalBtn {
  /* Allot Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 50px;
  gap: 10px;

  border: 2px solid rgba(46, 139, 87, 0.6);
  border-radius: 14px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;

  letter-spacing: -0.03em;

  color: #2e8b57;
  font-stretch: 95;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.file-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(169, 169, 169, 1);
  height: 45px;
  border-radius: 5px;
  padding: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;

  letter-spacing: -0.03em;
  padding: 10px;
  color: #8d8d8d;
  margin: 10px 0;
  padding: 10px 10px 10px 15px;
}

/* Hide the actual file input */
.file-input {
  display: none;
}

/* Style the label to look like a button */
.file-label {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 15px;
  gap: 10px;

  background: #f1f1f1;
  border: 0.5px solid #a9a9a9;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: -0.03em;

  color: #8d8d8d;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.selected-items-container {
  margin-top: 20px;
}

.selected-item {
  margin: 5px;
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  display: inline-block;
}


.modalSelect{
  /* Select PG */
width: 100%;
box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 8px 19px;


border: 1px solid #A9A9A9;
border-radius: 4px;



font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;

letter-spacing: -0.03em;

color: #8D8D8D;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


background-color: transparent;
outline: 0px;

}

.modalSelect:focus{
  outline: none;
}

.optionsDiv{
  display: flex;
  flex-direction: column;
  max-height: 200px;
  border-radius: 5px;
  overflow: hidden;
  overflow-y: scroll;

}

.option{
  
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 515;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  
  color: #8D8D8D;
  font-stretch: 95;
  padding: 10px 5px;
  
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  
}

.selectedPgDiv{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.selectPgbadge{
  /* Frame 116 */

box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 6px 10px;
gap: 5px;
margin: 5px 10px 5px 0px;

background: #FFFFFF;
border: 0.5px solid #F0F0F0;
box-shadow: 0px 8px 3px rgba(0, 0, 0, 0.01), 0px 5px 3px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.09), 0px 1px 1px rgba(0, 0, 0, 0.1);
border-radius: 10px;



font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 18px;
line-height: 25px;


color: #2E8B57;
font-stretch: 95;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


}

.file-input-container1{
  /* Frame 88 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14px 20px;
gap: 10px;


background: rgba(169, 169, 169, 0.6);
border-radius: 10px;
cursor: pointer;

}

.file-label1{

  cursor: pointer;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: #252A34;

background-color: transparent;
box-shadow: none;
flex: none;
order: 0;
flex-grow: 0;

}

.file-input-container2{
/* Frame 89 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 14px 64px;
gap: 10px;



background: #2E8B57;
border-radius: 10px;


font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
/* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: #FFFFFF;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
cursor: pointer;

}




@media only screen and (max-width: 576px) {
  .modalContentInner{
    width: 100%;
  }


.modalContent {
  /* Add Items */

  box-sizing: border-box;
  /* width: 300px; */
  /* height: 300px; */

  background: #ffffff;
  border: 1px solid #a9a9a9;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
}

 
.modalContentInner > h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  margin-bottom: 10px;
  /* identical to box height */
  text-align: center;

  color: #000000;
}

.modalContentInner .inputContainer {
  width: 250px;
  /* background-color: black; */
}

.modalContentInner .inputContainer input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  /* Frame 106 */
  border: 1px solid rgba(169, 169, 169, 1);

font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 14px;
line-height: 20px;
letter-spacing: -0.03em;

color: #000000;
font-stretch: 95;
padding: 10px 10px 10px 15px;

  
  margin: 10px 0;
}

.file-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(169, 169, 169, 1);
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  letter-spacing: -0.03em;
  padding: 10px;
  color: #8d8d8d;
  margin: 10px 0;
  padding: 10px 10px 10px 15px;
}

/* Style the label to look like a button */
.file-label {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  gap: 10px;

  background: #f1f1f1;
  border: 0.5px solid #a9a9a9;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;

  letter-spacing: -0.03em;

  color: #8d8d8d;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}


.modalBtn {
  /* Allot Button */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 10px;

  border: 2px solid rgba(46, 139, 87, 0.6);
  border-radius: 14px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0;

  letter-spacing: -0.03em;

  color: #2e8b57;
  font-stretch: 95;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

}
