.cardsContiner {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(240px, 1fr)
  ); /* Adjusted to auto-fit for centering */
  grid-gap: 20px;
  justify-content: center; /* Center the grid items */
  align-items: start; /* Align items at the top */
  padding: 20px; /* Add some padding for spacing */
  /* background-color: red; */
}

.addCards {
  min-width: 250px;
  width: 18%;
  height: 208px;

  background: #f0f0f0;
  border-radius: 6px;
  margin: 20px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Add Items */
  cursor: pointer;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  color: #a9a9a9;
}

.cardMainDIv {
  min-width: 250px;
  width: 20%;
  height: 208px;
  cursor: pointer;
  /* background: #F0F0F0; */
  border-radius: 6px;
  margin: 20px 0;
  border: 2px solid rgba(217, 217, 217, 1);
  overflow: hidden;
  /* margin:20px !important; */
}

.cardMainDIv > img {
  width: 100%;
  height: 83%;
  /* border-radius: 6px; */
}

.cardDetails {
  border-top: 2px solid rgba(217, 217, 217, 1);
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
}

.cardDetails > p {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 515;
  line-height: 24.51px;
  letter-spacing: -0.03em;
  text-align: left;
  font-variation-settings: "wdth" 95;
  text-align: center;
  padding: 5px 10px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 18px;
  line-height: 25px;
  /* Towel (106) */

  letter-spacing: -0.03em;

  color: #000000;
  font-stretch: 95;
}

.cardDetails > p > span {
  color: rgba(46, 139, 87, 1);
}

.cardDetails > button {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 576px) {

  .cardsContiner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    grid-gap: 0px;
  }
  .addCards {
    width: 48%;
    min-width: 0px;
    height: 170px;
  }

  .addCards > p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    leading-trim: both;
    text-edge: cap;
    color: #a9a9a9;
  }

  .addCards > img {
    width: 13px;
    height: 13px;
  }

  .cardMainDIv{
    width: 48%;
    min-width: 0px;
    height: 170px;
    border: 0px;
  }



  .cardMainDIv > img {
    border-bottom: 0px ;
    border: 1px solid rgba(217, 217, 217, 1);
    height: 110px;
  }

  .cardDetails{
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
  }

  .cardDetails > p {
    padding: 0px;
    margin: 0px;
    text-align: left;
    padding: 0 0 0 5px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 515;
    font-size: 14px;
    line-height: 19px;

    letter-spacing: -0.03em;

    color: #000000;
    font-stretch: 95;
  }

  .cardDetails > button {
    /* Frame 129 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin: 5px 0 0 0;
    border: 1px solid #2e8b57;
    border-radius: 0px 0px 3px 3px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 515;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: -0.03em;

    color: #2e8b57;
    font-stretch: 95;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* marg */
  }

  .cardDetails > button > img {
    display: none;
  }

}
