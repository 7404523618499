.workerHeaderInfo {
  /* width: 400px; */
  height: 50px;
  /* background-color: red; */
  margin: 0 30px 0 0;
}

.workerHeaderInfo > ul {
  display: flex; /* Display items in a row */
  gap: 10px; /* Add gap between items */
  padding: 0; /* Remove default padding */
  margin: 0;
}

.workerHeaderInfo > ul > li {
  list-style: none;
  margin: 0px 10px;
  list-style-image: url("../../CommonIcons/bullet.svg");

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.03em;

  color: #252a34;
  font-stretch: 95;
}

.workerHeaderInfo > ul > li > span {
  color: #2e8b57;
}

.selectedItemMain {
  list-style: none;
  position: relative;
}

.selectedItemMain > input[type="checkbox"] {
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;
}

.selectedItemInput {
  width: 50px;

  position: absolute;
  right: 10px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
  font-stretch: 95;
  text-align: right;

  border-bottom: 1px solid rgba(46, 139, 87, 1);
}

.selectedItemInput::-webkit-inner-spin-button,
.selectedItemInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.selectedItemInput {
  /* Additional styling if needed */
  -moz-appearance: textfield; /* Firefox */
}

.checkedUncked {
  position: absolute;
  top: 10px;
  left: 10px;
}

.BtnConatiner{
  width: 100%;
  position: fixed;
  /* background-color: red;  */
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.8);
  padding: 10px 10px;
  
  /* left: calc(50% - 50px); */
}

.SelectedItemsAllotBtn {
  /* Allot */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 60px;
  gap: 10px;

  background: #305641;
  border: 1.5px solid #2e8b57;
  box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05),
    0px 5px 5px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 14px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 30px;
  line-height: 41px;

  color: #ffffff;
  font-stretch: 95;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.SelectedItemsAllotedBtn{
  /* Property 1=Frame 111 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px 70px;
gap: 10px;


background: #FFFFFF;
border: 1.5px solid #2E8B57;
box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05), 0px 5px 5px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 14px;



font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 30px;
line-height: 41px;

letter-spacing: -0.03em;

color: #305641;
font-stretch: 95;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


}


.SelectedItemsReciveBtn{
  /* Property 1=Default */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 60px;
gap: 10px;


background: #2A3E82;
border: 1.5px solid #1E51F9;
box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05), 0px 5px 5px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 14px;



font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 30px;
line-height: 41px;

letter-spacing: -0.03em;

color: #FFFFFF;
font-stretch: 95;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


}


.SelectedItemsReievedBtn{
  /* Property 1=Variant2 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 60px;
gap: 10px;


background: #FFFFFF;
border: 1.5px solid #1E51F9;
box-shadow: 0px 22px 9px rgba(0, 0, 0, 0.01), 0px 12px 7px rgba(0, 0, 0, 0.05), 0px 5px 5px rgba(0, 0, 0, 0.09), 0px 1px 3px rgba(0, 0, 0, 0.1);
border-radius: 14px;

/* Received */

width: 115px;
height: 21px;

font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 30px;
line-height: 41px;

letter-spacing: -0.03em;

color: #2A3E82;
font-stretch: 95;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;


}


.middedMsg{
  width: 100%;
  height: 200px;
  /* background-color: red; */
  font-size: 60px;
  text-align: center;
  line-height: 100%;
}

.WorkerMainFlowMainDiv{
  padding: 0 0 90px 0;
  position: relative;
}


@media only screen and (max-width: 576px) {
  .selectedItemMain {
    /* display: none !important; */
  }

  .selectedMobItemMain{
    width: 50%;
  }

  .cardMainDIv{
    width: 48% !important;
  }
}