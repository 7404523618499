/* Styles for DefectedMissingItems table */
.defectedTableContainer {
    margin: 20px;
    padding: 0px 20px 20px;
    border-radius: 8px;
    max-height: calc(100vh - 220px); /* Set the maximum height for the table container */
    overflow-y: auto; /* Allow vertical scrolling */
  }
  
  .defectedTableContainer h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .defectedTable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .defectedTable thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: rgba(46, 139, 87, 1);
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    padding: 12px 15px;
    text-align: left;
  }
  
  .defectedTable th,
  .defectedTable td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  

  
  .defectedTable tbody tr:hover {
    background-color: #e0f7fa;
  }
  
  .defectedTable p {
    color: #888;
    font-style: italic;
    text-align: center;
  }
  

  /* defected.css */
.damagedRow {
  background-color: rgba(255, 0, 0, 0.1); /* Light red for Damaged rows */
}

.missingRow {
  background-color: rgba(255, 255, 0, 0.2); /* Light yellow for Missing rows */
}

.defectedTable tr:hover {
  background-color: rgba(0, 0, 0, 0.1); /* Highlight row on hover */
}
