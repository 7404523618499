.addAprtClass{


font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 27px;
color: #A9A9A9;

text-align: center;
}

.addRoomCUstom {
    height: 90px;
    width: 150px !important;
}

.roomCardDIv{
    height: 90px;
    width: 150px !important;
border: 2px solid rgba(217, 217, 217, 1);
background-color: white;
/* 102 (2) */

font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 20px;
line-height: 27px;
/* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: #000000;
font-stretch: 95;
display: flex;
flex-direction: row;
align-items: center;

}

.roomCardDIv > span{
    color: rgba(46, 139, 87, 1);
    padding: 0 0 0 5px;
}

.uploadDamitemsBtn{
    /* Frame 86 */
width: fit-content;
box-sizing: border-box;
/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 18px;
gap: 10px;
border: 1px solid #FF7575;
border-radius: 20px;


font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 18px;
line-height: 25px;
leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: #FF7575;
font-stretch: 95;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;
cursor: pointer;


}

.flowContainer{
    width: 100%;
    height: 100px;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flowss{
    /* Frame 119 */

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 10px 10px; 
gap: 10px;


/* background: rgba(46, 139, 87, 0.2); */
border-radius: 4px;
width: 15%;
text-align: center;
/* background-color: black; */
/* Frame 120 */

box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;



border: 1px solid #D9D9D9;
border-radius: 4px;


font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 25px;

color: #A9A9A9;


flex: none;
order: 1;
flex-grow: 0;
text-align: center;
}

.activeCircle{
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgba(46, 139, 87, 1);
}

.active-state{background: rgba(46, 139, 87, 0.2);
    color:  rgba(46, 139, 87, 1);
    
}

.redBtn{
    /* Damage Button */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px;
gap: 10px;

border: 1.5px solid rgba(255, 117, 117, 0.6);
border-radius: 14px;


font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 20px;
line-height: 25px;

leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: #FF7575;
font-stretch: 95;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

}


.blueBtn{
    /* Collect Button */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px;
gap: 10px;

border: 1.5px solid rgba(30, 81, 249, 0.6);
border-radius: 14px;


font-family: 'Open Sans';
font-style: normal;
font-weight: 515;
font-size: 20px;
line-height: 25px;

leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: rgba(30, 81, 249, 1);
font-stretch: 95;




}

.greenBtn{
    border: 1.5px solid rgba(46, 139, 87, 0.6);
    color: rgba(46, 139, 87, 1);

}

.BtnConatner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0px ;
}