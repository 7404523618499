.headerMainDiv {
  width: 100% !important;
  /* height: 50px; */
  background: #252a34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerLogo{
    padding: 10px 30px;
}


.otherLogo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
    padding: 0 20px 0 0;
    width: fit-content;
    flex-grow: 0;
    cursor: pointer;
}


.mainSearchBar > input{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 20px;

leading-trim: both;
text-edge: cap;
letter-spacing: -0.03em;

color: #A9A9A9;
background-color: transparent;
outline: 0px;
border: 0px;


/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

}

.mainSearchBar{
    /* Frame 80 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 20px 8px 30px;
gap: 10px;

margin: 0 30px 0 0;



background: rgba(255, 255, 255, 0.1);
border-radius: 10px;

}

/* css for header navigation */


.headerNavigationMainDiv{
    /* Frame 22 */

box-sizing: border-box;

/* width: 100%; */
padding: 20px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;



}

.headerNavigation{
    width: 97%;
    /* max-width: 1440px; */
    /* height: 30px; */
    border: 1px solid rgba(37, 42, 52, 0.3);
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    /* Home / Allot Items / Worker */
    text-align: center;
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;

color: #252A34;


}

.navigationLink{
    /* Home / Allot Items / Worker */

    text-decoration: none; 

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
/* identical to box height */

padding: 0 5px;
color: #252A34;


}

.mainApp{
    height: calc(100vh - 173px);
}

.profileMainContainer{
    width: 100%;
    height: 500px;
    /* padding: 0 50px ; */
    /* background-color: red; */
}

.entryContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    max-width: 400px;
}

.entryContainer > p:first-child{

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
/* identical to box height */
width: 150px;

color: #000000;
/* color: red; */

}

.entryContainer > p:last-child{

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;

color: #A9A9A9;

}

.entryContainer{

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
/* identical to box height */

color: #FF7575;


}


@media only screen and (max-width: 576px) {
    .headerNavigation {
      border: none !important;
    }
  
    .navigationLink ,.headerNavigation {
      color: #7d8698;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }

    .homePageNavigationMainDiv{
        flex-direction: column;
    }

    .headerMainDiv {
        height: 60px;
    }

    .headerLogo > img{
        height: 40px;
    }
    .headerNavigationMainDiv{
        padding-bottom: 0px;
    }
  }
/* Hides the Google Translate banner */
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

iframe{
    /* z-index: -1 !important; */
}

/* Adjusts the body position */
body {
    top: 0px !important;
}

/* Styles for the Google Translate element */
#google_translate_element {
    display: inline-block;
    margin: 10px;
}

/* Styles the Google Translate gadget */
.goog-te-gadget-simple {
    background-color: #f1f1f1;
    border: 1px solid #d5d5d5;
    padding: 4px;
    border-radius: 4px;
    font-size: 13px;
    color: #333;
}





/* Text color for the menu value */
.goog-te-gadget-simple .goog-te-menu-value {
    color: #333;
}

/* Margin for the menu value span */
.goog-te-gadget-simple .goog-te-menu-value span {
    margin-right: 3px;
}

/* Hides the Google Translate icon */
.goog-te-gadget-icon {
    display: none;
}


.headerNavBtn{
    clip-path: polygon(75% 0%, 85% 50%, 75% 100%, 0% 100%, 10px 50%, 0% 0%);
    padding: 5px 40px 5px 20px;
    background-color: #252a34;
    color: white;
    width: fit-content;
}