@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

*::-webkit-scrollbar {
  display: none;
}

input {
  outline: 0px;
  background-color: white;
  border: 0px;
}

/* Remove default styles from all anchor tags */
a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Inherits color from parent element */
  background: transparent; /* Ensures no background color */
  border: none; /* Removes any border */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  font: inherit; /* Ensures font properties are inherited from parent */
  cursor: pointer; /* Ensures cursor is pointer to indicate clickability */
  outline: none; /* Removes focus outline */
}

/* Remove hover and active styles if necessary */
a:hover,
a:focus,
a:active {
  text-decoration: none; /* Removes underline on hover, focus, and active */
  color: inherit; /* Ensures color remains the same */
  background: transparent; /* Ensures no background color */
  border: none; /* Removes border */
  outline: none; /* Removes focus outline */
}

/* Remove default styles from buttons */
button {
  all: unset; /* Unsets all inherited properties */
  display: inline; /* Reset the display property to inline */
  cursor: pointer; /* Ensure the button has a pointer cursor */
}

/* If you want to ensure button looks like a button */
button {
  display: inline-block; /* Provides more control over sizing and spacing */
  padding: 0; /* Remove default padding */
  border: none; /* Remove default border */
  background: transparent; /* Remove default background */
  font: inherit; /* Inherit font styles from parent */
  color: inherit; /* Inherit color from parent */
}

.mainApp {
  /* background-color: red; */
  position: relative;
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
}

.mainApp::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.majorDisplayMainDiv {
  height: 100%;
  overflow-y: scroll;
}

.majorDisplayMainDiv > div {
  /* background-color: red;1 */
  height: calc(100% - 30px);
  overflow-y: scroll;
}



#google_translate_element {
  display: inline-block;
  margin: 10px;
  background-color: red;
}
.goog-te-banner-frame.skiptranslate {
  /* display: none !important; */
}


@media only screen and (max-width: 576px) {
  .majorDisplayMainDiv > span > h2{
    font-size: 15px;
  }

  .workerHeaderInfo > ul > li{
    font-size: 12px;
  }
}