.homePageMainDiv {
  flex-grow: 1;
  /* background-color: red; */
  /* height: 100%; */
  /* overflow: hidden; */
  /* height:calc(100vh-173px); */
}

.homePageNavigationMainDiv {
  width: 98%;

  /* height: 200px; */
  /* background-color: red; */
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1.5px dashed rgba(169, 169, 169, 1);
  padding: 20px 0 50px 0px;
  /* position: sticky; */
  flex: 0 0 auto;
  /* flex-wrap: wrap; */
}

.homePageNavDiv {
  /* Frame 23 */

  height: 150px;
  width: 20%;
  /* min-width: 250px; */
  max-width: 380px;

  box-sizing: border-box;
  /* background: rgba(152, 255, 152, 0.6); */
  border: 2px solid;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.homePageNavDiv2 {
  /* Frame 23 */

  height: 130px;
  width: 30%;
  max-width: 250px;

  box-sizing: border-box;
  /* background: rgba(152, 255, 152, 0.6); */
  border: 2px solid;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.homePageNavDiv > p {
  /* Allot */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  margin: 10px 0 0 0;
  text-align: center;
  /* identical to box height */
}

.homeItemsMainDiv {
  width: 98%;
  /* height: 500px; */
  padding: 25px;
  /* background-color: red; */
  margin: 0 auto;
  display: flex;

  flex-direction: column;
  /* background-color: red; */
}

.homeItemsMainDiv h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 0 20px;
}

.itemsContiner {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));

  /* height: 200px; */
  /* background-color: red; */
}

.addItem {
  min-width: 250px;
  width: 18%;
  height: 208px;

  background: #f0f0f0;
  border-radius: 6px;
  margin: 20px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Add Items */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  cursor: pointer;
  color: #a9a9a9;
}

.itemCardMainDIv {
  /* Frame 62 */
  min-width: 250px;
  width: 18%;
  height: 208px;

  /* background: #F0F0F0; */
  border-radius: 6px;
  margin: 20px 0;
  border: 2px solid rgba(217, 217, 217, 1);
  /* overflow: hidden; */
  position: relative;
  /* Frame 62 */
}

.itemCardMainDIv > img {
  width: 100%;
  height: 83%;
  /* border-radius: 6px; */
}

.itemsDetails {
  border-top: 2px solid rgba(217, 217, 217, 1);
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
}

.itemsDetails > p {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 515;
  line-height: 24.51px;
  letter-spacing: -0.03em;
  text-align: left;
  font-variation-settings: "wdth" 95;
  text-align: center;
  padding: 5px 10px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 515;
  font-size: 18px;
  line-height: 25px;
  /* Towel (106) */

  letter-spacing: -0.03em;

  color: #000000;
  font-stretch: 95;
}

.itemsDetails > p > span {
  color: rgba(46, 139, 87, 1);
}

.itemsDetails > button {
  width: 30px;
  height: 30px;
  font-size: 0px;
}

@media only screen and (max-width: 576px) {
  .homePageNavigationMainDiv {
    flex-direction: column;
  }

  .homePageNavDiv2,
  .homePageNavDiv {
    width: 100%;
    max-height: 180px;
    margin: 10px 0px;
  }

  .homeItemsMainDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px !important;
  }

  .homeItemsMainDiv > h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: left;
    padding: 10px 0 10px 0;
  }

  .addItem {
    width: 48%;
    height: 170px;
    min-width: 0;
  }

  .addItem > p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    leading-trim: both;
    text-edge: cap;
    color: #a9a9a9;
  }

  .addItem > img {
    width: 13px;
    height: 13px;
  }

  .itemCardMainDIv {
    width: 48%;
    min-width: 0px;
    height: 170px;
  }

  .itemsContiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    grid-gap: 0px;
  }

  .itemCardMainDIv {
    border: 0px;
  }

  .itemCardMainDIv > img {
    border: 1px solid rgba(217, 217, 217, 1);
    height: 110px;
  }

  .itemsDetails {
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
  }

  .itemsDetails > p {
    padding: 0px;
    margin: 0px;
    text-align: left;
    padding: 0 0 0 5px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 515;
    font-size: 14px;
    line-height: 19px;

    letter-spacing: -0.03em;

    color: #000000;
    font-stretch: 95;
  }

  .itemsDetails > button {
    /* Frame 129 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 98%;
    margin: 5px 0 0 0;
    border: 1px solid #2e8b57;
    border-radius: 0px 0px 3px 3px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 515;
    font-size: 12px;
    line-height: 16px;

    letter-spacing: -0.03em;

    color: #2e8b57;
    font-stretch: 95;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* marg */
  }

  .itemsDetails > button > img {
    display: none;
  }

  .homePageMainDiv {
    padding: 0px;
  }

  .homePageNavigationMainDiv{
    padding-top: 0px;
  }
}


.toolTip{
  position: absolute;
  right: 0px;
  top: 0px;
  /* width: 50px; */
  /* height: 20px; */
  color: white;
  background-color: rgba(0,0,0,0.7);
  padding: 3px;
  /* border-radius: 10px; */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
}
